import { initializeApp } from 'firebase/app';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';

import { UserInfoForm } from './models/user';
import { formatTwoDigits, generateFirestoreId } from './utils';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

export const firebaseDb = getFirestore(firebaseApp);

export const usersRef = collection(firebaseDb, 'users');

export const addUser = async (user: UserInfoForm, scores: number[]) => {
  return setDoc(doc(usersRef, generateFirestoreId()), {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    postalCode: user.postalCode,
    termsAccepted: user.tncAccept ? 'Yes' : 'No',
    agreeToContact: user.receiveEmail ? 'Yes' : 'No',
    birthday: `${user.birthYear}-${formatTwoDigits(
      user.birthMonth,
    )}-${formatTwoDigits(user.birthDay)}`,
    scores,
    time: new Date().getTime(),
  });
};
